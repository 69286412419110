.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.project-icon {
    margin-right: 10px;
    width: 30px;
    height: 30px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link, .nav-link {
  color: #88ccfb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-container {
    margin: 0.5em 5%;
}

.align-right {
  text-align: right;
}
.row.table-control {
  padding-bottom: 0.5em;
}
.table-control-col .btn-primary {
  margin: 0.1em;
  box-shadow: 0 0 1px 1px #eee;
  
}
@media (max-width: 1200px) {
  .table-control-col .btn, .table-control-col {
    font-size: 0.85rem;
  }  
}
label.table-control {
  float: left;
  font-weight: bold;
  padding: 0.5em;
}
th {
  font-size: 0.9em;
  position: relative;
}
th .sort-caret {
  position: absolute;
  top: 0.5em;
  right: 0.2em;
}
th.sort-column, td.sort-column {
  background-color: #ddf8ff;

}
.control-sort span {
    max-width: 92%;
    display: inline-block;
    float: left;
    overflow-x: hidden;
}
svg.pop-range {
  margin-left: 0.2em;
  margin-bottom: 0.25em;
}
svg.data-date {
  margin-left: -1em;
  margin-bottom: 0.25em;
}
.range-spacer {
    width: 10em;
    height: 0.5em;
}
.button.data-date {
  background:#007bff;
  color: #fff;
  padding: .375rem .75rem;
  border-radius: .25rem;
  float: left;
}
span.data-date {
  float: left;
  padding-top: 0.05em;
}
.data-date-input {
    padding: 0 0.5em;
    border-radius: 2px;
    background: #007bff;
    float: left;
}
.DayPickerInput {
  min-width: 6em;
}
.DayPickerInput input{
    background: #007bff;
    color: white;
    border: none;
    width: 7em;
}
.DayPickerInput-OverlayWrapper {
  color: black;
}
.data-table {
    position: relative;
}
.loader {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: #ddda;
    display: none;
    padding: 10% 35%;
}
.loader.loading {
    display: block;
}
.loader > div {
    width: 100%;
}
.loader svg {
    width: 100%;
    height: 100%
}
